import { template as template_c5e06465581344489d9dbf87c6da7ecd } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import colors from '@blakeelearning/blake-colours/colours';
import { LeftSplitChart } from 'district-ui-client/components/left-split-chart';
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box';
import { eq } from 'ember-truth-helpers';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
import SeriesColumn from 'district-ui-client/components/primitives/series-column/component';
import type { SeriesColumnOptions } from 'highcharts';
export interface DrivingTestsTotalsData {
    grade_position: number;
    summary: {
        category: string;
        count: number;
    }[];
    months: {
        month: string;
        count: number;
    }[];
}
interface Signature {
    Args: {
        data?: DrivingTestsTotalsData;
        product: 're' | 'maths';
        grade?: string;
        tooltip?: string;
        title?: string;
    };
    Element: HTMLDivElement;
}
export class ChartsDrivingTestsTotals extends Component<Signature> {
    get summaryStats() {
        const summaryData = this.args.data?.summary ?? [];
        const totalCount = summaryData.find((d)=>d.category === 'total_count')?.count ?? 0;
        const studentCount = summaryData.find((d)=>d.category === 'student_count')?.count ?? 0;
        const average = basicAverage(totalCount, studentCount);
        return [
            {
                label: 'Driving Tests Mastered',
                value: formatNumber(totalCount, 1)
            },
            {
                label: 'Students',
                value: formatNumber(studentCount, 1)
            },
            {
                label: 'Tests per Student',
                value: formatNumber(average, 1)
            }
        ];
    }
    /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */ get columns(): SeriesColumnOptions[] {
        const monthData = this.args.data?.months ?? [];
        const colorMap = {
            re: colors.stormBlue300,
            maths: colors.oceanyBlue300
        };
        return [
            {
                name: 'count',
                data: monthData.map((d)=>d.count),
                color: colorMap[this.args.product],
                type: 'column'
            }
        ];
    }
    /**
   * Computed list of month data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */ get months() {
        return this.args.data?.months?.map((m)=>convertDateFormat(m.month)) ?? [];
    }
    static{
        template_c5e06465581344489d9dbf87c6da7ecd(`
    <div data-test-driving-tests-totals class="relative" ...attributes>
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{this.summaryStats}}
            @colorScheme={{if (eq @product "re") SummaryBoxColorScheme.DarkBlue SummaryBoxColorScheme.LightBlue}}
          />
        </:left>
        <:right>

          <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
          <SeriesColumn
            @title={{@title}}
            @data={{this.columns}}
            @categories={{this.months}}
            @stackingStyle="normal"
            @chartSpacing={{Array 10 10 10 10}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartsDrivingTestsTotals;
