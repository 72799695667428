import { template as template_ea30b8b145194be58a7d6725667aa794 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import orderBy from 'lodash/orderBy';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import type Teacher from 'district-ui-client/models/teacher';
import { on } from '@ember/modifier';
import { t, formatDate } from 'ember-intl';
import { eventValue } from 'district-ui-client/helpers/event-value';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { fn } from '@ember/helper';
import { includes } from 'district-ui-client/helpers/includes';
import UiButtonLinkTo from 'district-ui-client/components/ui-button/link-to';
import { colspanMax } from 'district-ui-client/modifiers/colspan-max';
import { FormComponentsFormSelect } from 'district-ui-client/components/form-components/form-select';
import type { SchoolOption } from 'district-ui-client/controllers/subscription-type/manage/teachers';
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator';
interface Args {
    subscriptionType?: SubscriptionType;
    teachers: Teacher[];
    selectedIds: string[];
    updateSelectedIds?: (selectedIds: string[]) => void;
    isCleverDisabled?: boolean;
    hasSchoolSelector?: boolean;
    sortedSchoolsOptions?: SchoolOption[];
    selectSchool?: (option?: SchoolOption) => void;
    selectedSchoolOption?: SchoolOption | undefined;
    isTeachersPending?: boolean;
}
interface Signature {
    Args: Args;
    Blocks: {
        default: [];
    };
}
export class TeacherTable extends Component<Signature> {
    @tracked
    sortProperties = [
        'lastName:asc'
    ];
    @tracked
    search = '';
    get teacherViewModels() {
        const { subscriptionType } = this.args;
        return this.args.teachers.map((teacher)=>{
            let numberOfStudents = 0;
            let trialEndDate;
            /* optional because sometimes this table shows teachers NOT for the current subtype (add existing teacher page).
       * If you were to do so, you may end up with multiple trial dates, and would need school classes for all _other_
       * sub types.
       */ if (subscriptionType) {
                numberOfStudents = teacher.schoolClasses?.reduce((sum, sc)=>(sc.subscriptionType === subscriptionType ? sum + sc.studentCount : sum), 0) ?? 0;
                // filter by active, sort by latest endDate first
                const sortedTeacherSubs = teacher.teacherSubscriptionsFor(subscriptionType).filter((sub)=>sub.isActive).sort((a, b)=>b.endDate.getTime() - a.endDate.getTime());
                trialEndDate = sortedTeacherSubs[0]?.endDate;
            }
            return {
                id: teacher.id,
                firstName: teacher.firstName,
                lastName: teacher.lastName,
                login: teacher.login,
                numberOfStudents,
                trialEndDate,
                schoolName: teacher.school?.name
            };
        });
    }
    get arrangedContent() {
        const properties = this.sortProperties.map((sortProperty)=>sortProperty.split(':')[0]);
        const directions = this.sortProperties.map((sortProperty)=>sortProperty.split(':')[1]).filter((direction)=>{
            return direction === 'asc' || direction === 'desc';
        });
        return orderBy(this.teacherViewModels, properties, directions);
    }
    get filteredContent() {
        const lowerCaseSearch = this.search.toLowerCase();
        return this.arrangedContent.filter((content)=>`${content.firstName} ${content.lastName} ${content.login} ${content.schoolName}`.toLowerCase().includes(lowerCaseSearch));
    }
    get isAllSelected() {
        return this.filteredContent.every((t)=>this.args.selectedIds.includes(t.id));
    }
    setSearch = (value: string)=>{
        this.search = value;
    };
    selectSchool = (option?: SchoolOption)=>{
        this.setSearch('');
        this.args.selectSchool?.(option);
    };
    @action
    sortBy(property: string) {
        // Only supports 1 column, but can be extended.
        const [sortProperty, sortDirection] = this.sortProperties[0].split(':');
        if (sortProperty === property) {
            this.sortProperties = [
                `${property}:${sortDirection === 'asc' ? 'desc' : 'asc'}`
            ];
        } else {
            this.sortProperties = [
                `${property}:asc`
            ];
        }
    }
    @action
    onSelectAll() {
        if (this.isAllSelected) {
            this.args.updateSelectedIds?.([]);
        } else {
            this.args.updateSelectedIds?.(this.filteredContent.map((t)=>t.id));
        }
    }
    @action
    onSelectTeacher(teacherId: string) {
        if (this.args.selectedIds.includes(teacherId)) {
            this.args.updateSelectedIds?.(this.args.selectedIds.filter((id)=>id !== teacherId));
        } else {
            this.args.updateSelectedIds?.([
                ...this.args.selectedIds,
                teacherId
            ]);
        }
    }
    static{
        template_ea30b8b145194be58a7d6725667aa794(`
    <div>
      <div class="pb-3 text-center">
        {{! if using schoolSelector render both search and selector in a separate div so yielded elements are on the next line, otherwise just render search component inline }}
        {{#if @hasSchoolSelector}}
          <div>
            <form class="form-inline mx-4 mb-3 inline-block align-middle">
              <div class="has-feedback overflow-hidden">
                <input
                  data-test-search-teachers
                  class="form-control"
                  type="search"
                  aria-label={{t "searchPlaceholder.teacher"}}
                  placeholder={{t "searchPlaceholder.teacher"}}
                  value={{this.search}}
                  {{on "input" (eventValue this.setSearch)}}
                />
                <span class="form-control-feedback">
                  <FaIcon @icon="magnifying-glass" />
                </span>
              </div>
            </form>
            <div class="mx-3 mb-3 inline-block align-middle">
              {{#if @sortedSchoolsOptions}}
                <FormComponentsFormSelect
                  data-test-search-by-school
                  @defaultText="Select a school"
                  @search={{true}}
                  @options={{@sortedSchoolsOptions}}
                  @searchPlaceholder="Search schools"
                  @optionClick={{this.selectSchool}}
                  @value={{@selectedSchoolOption}}
                  @resetAction={{fn this.selectSchool undefined}}
                />
              {{/if}}
            </div>
          </div>
        {{else}}
          {{! linting rules to avoid having two form elements, however there shouldn't be a case where both form elements are rendered }}
          {{! template-lint-disable no-duplicate-landmark-elements }}
          <form class="form-inline mx-4 mb-3 inline-block align-middle">
            <div class="has-feedback overflow-hidden">
              <input
                data-test-search-teachers
                class="form-control"
                type="search"
                aria-label={{t "searchPlaceholder.teacher"}}
                placeholder={{t "searchPlaceholder.teacher"}}
                value={{this.search}}
                {{on "input" (eventValue this.setSearch)}}
              />
              <span class="form-control-feedback">
                <FaIcon @icon="magnifying-glass" />
              </span>
            </div>
          </form>
        {{/if}}

        {{yield}}
      </div>

      {{#if @isTeachersPending}}
        <FidgetLoadingIndicatorComponent
          @show={{@isTeachersPending}}
          @overlay={{false}}
          @centered={{true}}
          class="relative top-6"
        />
      {{else}}
        <div class="overflow-y-auto pb-3">
          <table class="disco-table w-full bg-white">
            <thead>
              <tr>
                <th class="th-checkbox">
                  <input
                    type="checkbox"
                    class="cursor-pointer"
                    checked={{this.isAllSelected}}
                    aria-label={{if this.isAllSelected (t "uncheckAll") (t "checkAll")}}
                    {{on "input" this.onSelectAll}}
                  />
                </th>
                <th class="text-left" role="button" {{on "click" (fn this.sortBy "firstName")}}>
                  {{t "components.teacherTable.firstName"}}
                  <FaIcon @icon="sort" @pull="right" />
                </th>
                <th class="text-left" role="button" {{on "click" (fn this.sortBy "lastName")}}>
                  {{t "components.teacherTable.lastName"}}
                  <FaIcon @icon="sort" @pull="right" />
                </th>
                {{#unless @isCleverDisabled}}
                  <th class="text-left" role="button" {{on "click" (fn this.sortBy "login")}}>
                    {{t "components.teacherTable.login"}}
                    <FaIcon @icon="sort" @pull="right" />
                  </th>
                {{/unless}}
                {{#if @subscriptionType}}
                  <th role="button" {{on "click" (fn this.sortBy "numberOfStudents")}}>
                    {{t "components.teacherTable.students"}}
                    <FaIcon @icon="sort" @pull="right" />
                  </th>
                  <th role="button" class="text-left" {{on "click" (fn this.sortBy "trialEndDate")}}>
                    {{t "components.teacherTable.trialEnd"}}
                    <FaIcon @icon="sort" @pull="right" />
                  </th>
                {{/if}}
                <th role="button" {{on "click" (fn this.sortBy "schoolName")}}>
                  {{t "components.teacherTable.schoolName"}}
                  <FaIcon @icon="sort" @pull="right" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {{#each this.filteredContent as |teacher index|}}
                <tr data-test-teacher-row={{index}}>
                  <td class="td-checkbox">
                    <input
                      type="checkbox"
                      class="cursor-pointer"
                      checked={{includes @selectedIds teacher.id}}
                      aria-label="{{teacher.firstName}} {{teacher.lastName}}"
                      {{on "input" (fn this.onSelectTeacher teacher.id)}}
                    />
                  </td>
                  <td>
                    {{teacher.firstName}}
                  </td>
                  <td>
                    {{teacher.lastName}}
                  </td>
                  {{#unless @isCleverDisabled}}
                    <td class="td-login">
                      {{teacher.login}}
                    </td>
                  {{/unless}}
                  {{#if @subscriptionType}}
                    <td align="center">
                      {{teacher.numberOfStudents}}
                    </td>
                    <td>
                      {{#if teacher.trialEndDate}}
                        {{formatDate teacher.trialEndDate day="2-digit" month="long" year="numeric"}}
                      {{else}}
                        {{t "components.teacherTable.notOnTrial"}}
                      {{/if}}
                    </td>
                  {{/if}}
                  <td align="center">
                    {{teacher.schoolName}}
                  </td>
                  <td align="center">
                    <UiButtonLinkTo
                      @route="subscription-type.manage.teachers.edit"
                      @model={{teacher.id}}
                      class="regular ui-btn-small"
                      aria-label="{{t
                        'components.teacherTable.edit'
                        firstName=teacher.firstName
                        lastName=teacher.lastName
                      }}"
                    >
                      <FaIcon @icon="pencil" />
                    </UiButtonLinkTo>
                  </td>
                </tr>
              {{else}}
                <tr>
                  <td {{colspanMax}}>{{t "components.teacherTable.noResults"}}</td>
                </tr>
              {{/each}}
            </tbody>
          </table>
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default TeacherTable;
