import { template as template_4cfc2022cca04b36b4f9daee58f32d03 } from "@ember/template-compiler";
import { SelectBox as BaseSelectBox } from '@blakeelearning/ember-select/components/select-box';
import { MakeListbox } from '@blakeelearning/ember-select/modifiers/make-listbox';
import { MakeSearchInput } from '@blakeelearning/ember-select/modifiers/make-search-input';
import { MakeSelectOption } from '@blakeelearning/ember-select/modifiers/make-select-option';
import type { SelectOption } from '@blakeelearning/ember-select';
import { t } from 'ember-intl';
import { not } from 'ember-truth-helpers';
import Component from '@glimmer/component';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import PopoverStandardDropdown from '@blakeelearning/popovers/components/popover/dropdowns/standard';
import { fn } from '@ember/helper';
import { ThemedDropdownButton, ThemedDropdownContent, type DropdownButtonStyle } from 'district-ui-client/components/themed-dropdown';
import { ThemedMenu, ThemedMenuGroup, ThemedMenuItem } from 'district-ui-client/components/themed-menu';
import groupBy from 'lodash/groupBy';
import { isPresent } from '@ember/utils';
interface Signature {
    Element: HTMLButtonElement;
    Args: {
        options: SelectOption[];
        onSelect: (value: SelectOption) => void;
        style: DropdownButtonStyle;
        placeholder?: string;
        searchPlaceholder?: string;
        beforeLabel?: string;
        value?: string;
        matchTriggerWidth?: boolean;
        disabled?: boolean;
    };
    Blocks: {
        option: [SelectOption];
    };
}
export function grouper(options: SelectOption[]) {
    return groupBy(options, (opt)=>(isPresent(opt.group) ? opt.group : '')) // treat nullable as empty string
    ;
}
export class SelectBox extends Component<Signature> {
    onSelectWithClose = (closeFunction: () => void, value: SelectOption)=>{
        this.args.onSelect(value);
        closeFunction();
    };
    static{
        template_4cfc2022cca04b36b4f9daee58f32d03(`
    <PopoverStandardDropdown
      @matchTriggerWidth={{@matchTriggerWidth}}
      @disabled={{@disabled}}
      as |dropdown status actions|
    >
      <BaseSelectBox
        @value={{@value}}
        @options={{@options}}
        @onSelect={{fn this.onSelectWithClose actions.close}}
        as |state|
      >
        <ThemedDropdownButton
          {{dropdown.makeTrigger}}
          @disabled={{@disabled}}
          @style={{@style}}
          class="inline-flex w-full items-center justify-between {{if status.isOpen 'active'}}"
          ...attributes
        >
          <span data-test-placeholder class="truncate text-left font-medium">
            {{if state.selected state.selectedLabel @placeholder}}
          </span>
          <FaIcon @icon="chevron-down" class="ml-2" />
        </ThemedDropdownButton>
        <dropdown.content>
          {{! container with round corners, then scroller, then padded content }}
          <ThemedDropdownContent data-test-dropdown-content @constrainWidth={{not @matchTriggerWidth}}>
            {{! The dropdown content should flip to other side if not enough room. }}
            {{! 45vh ensures that even if the trigger is mid-page, the content is not too big for _both_ sides }}
            <div class="max-h-[45vh] overflow-auto">
              <ThemedMenu class="flex flex-col" {{MakeListbox}}>
                {{#if @searchPlaceholder}}
                  <div class="mb-2 flex items-center rounded-l-full rounded-r-full bg-neutral-50 px-3 py-2">
                    <input
                      class="placeholder:text-neutral-250 w-full bg-neutral-50 text-sm text-neutral-400 outline-none ring-0"
                      placeholder={{@searchPlaceholder}}
                      type="search"
                      aria-label={{@searchPlaceholder}}
                      {{MakeSearchInput state}}
                    />
                    <FaIcon @icon="search" />
                  </div>
                {{/if}}
                {{#if @beforeLabel}}<div class="text-neutral-250 px-3 py-2 text-sm">{{@beforeLabel}}</div>{{/if}}
                {{#each-in (grouper state.options) as |groupLabel options|}}
                  <ThemedMenuGroup @label={{groupLabel}}>
                    {{#each options as |option|}}
                      <ThemedMenuItem data-select-option={{option.value}} {{MakeSelectOption state option}}>
                        {{#if (has-block "option")}}
                          {{yield option to="option"}}
                        {{else}}
                          {{option.label}}
                        {{/if}}
                      </ThemedMenuItem>
                    {{/each}}
                  </ThemedMenuGroup>
                {{else}}
                  <ThemedMenuGroup>
                    <li class="px-3 py-2 text-sm">{{t "noResultsFound"}}</li>
                  </ThemedMenuGroup>
                {{/each-in}}
              </ThemedMenu>
            </div>
          </ThemedDropdownContent>
        </dropdown.content>
      </BaseSelectBox>
    </PopoverStandardDropdown>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default SelectBox;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        DuiSelectBox: typeof SelectBox;
    }
}
