import { template as template_f8c6c744726d41209270037d91408b63 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { or } from 'ember-truth-helpers';
interface Signature {
    Element: HTMLAnchorElement;
    Args: {
        corners?: string;
        href?: string;
    };
    Blocks: {
        default: [];
    };
}
/**
  Usage:
    <UiButton::Link
      class="ui-btn-small"
      @href="/abc"
      @corners="rounded rounded-l-none"
    >
      abc
    </UiButton::Link>

  Must define @href or href.
  disabled not allowed on anchor tags. It's not good html or a11y-friendly, if you're reaching for that, consider a button instead
*/ export const UiButtonLink: TOC<Signature> = template_f8c6c744726d41209270037d91408b63(`
  <a class="__ui-button__fd7ee {{or @corners 'rounded'}}" href={{@href}} ...attributes disabled={{false}}>
    {{yield}}
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiButtonLink;
