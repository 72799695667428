import { template as template_f23b20395eac464385f62ab832c5f00d } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator';
export const LoadingSpinner: TOC<unknown> = template_f23b20395eac464385f62ab832c5f00d(`
  <FidgetLoadingIndicatorComponent @show={{true}} @overlay={{false}} @loadingText="Loading..." @centered={{true}} />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LoadingSpinner;
