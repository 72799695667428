import { template as template_8528bb6760b0429fb846afe882d6a7b1 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import LoadingSpinner from 'district-ui-client/components/loading-spinner';
import RouteTemplate from 'ember-route-template';
export const LoadingRouteTemplate: TOC<unknown> = template_8528bb6760b0429fb846afe882d6a7b1(`<LoadingSpinner />`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(LoadingRouteTemplate);
