import { template as template_507dd10bc95f466a8bdc29952f91ebed } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
export const ManagePage: TOC<unknown> = template_507dd10bc95f466a8bdc29952f91ebed(`
  <div class="px-8 py-10">
    {{outlet}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ManagePage);
