import { template as template_135262c122094ee4a9c54188f45e9cab } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
}
export const NotificationDot: TOC<Signature> = template_135262c122094ee4a9c54188f45e9cab(`
  <div class="inline-block h-1.5 w-1.5 shrink-0 grow-0 rounded-full bg-pink-300" ...attributes></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
