import { template as template_9211b3f334d5421fb5ec4c4ebb9b73d3 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator';
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
export const ManageTeachersLoadingRouteTemplate: TOC<unknown> = template_9211b3f334d5421fb5ec4c4ebb9b73d3(`
  <FidgetLoadingIndicatorComponent @show={{true}} @overlay={{false}} @loadingText={{t "loading"}} @centered={{true}} />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ManageTeachersLoadingRouteTemplate);
