import { template as template_c20b0d0745454484a89896c5cb4769c8 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import { service } from '@ember/service';
import type LogoutService from 'district-ui-client/services/logout';
import type SessionService from 'district-ui-client/services/session';
import { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import { LinkTo } from '@ember/routing';
import { eq } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import PopoverStandardDropdown from '@blakeelearning/popovers/components/popover/dropdowns/standard';
import t from 'ember-intl/helpers/t';
import reLogo from '@images/reading-eggs.svg';
import rexLogo from '@images/reading-eggspress.svg';
import fpLogo from '@images/fast-phonics.svg';
import msLogo from '@images/mathseeds.svg';
import wlLogo from '@images/writing-legends.svg';
import type ActiveRouteService from 'district-ui-client/services/active-route';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { fn } from '@ember/helper';
import { runTask } from 'ember-lifeline';
import { array } from '@ember/helper';
import { ThemedDropdownContent } from 'district-ui-client/components/themed-dropdown';
import { ThemedMenu, ThemedMenuGroup, ThemedMenuItemLinkTo } from 'district-ui-client/components/themed-menu';
import { FeatureNotificationId } from 'district-ui-client/services/feature-notification';
import { FeatureNotification } from 'district-ui-client/helpers/feature-notification';
import { NotificationDot } from 'district-ui-client/components/notification-dot';
import { Lozenge } from 'district-ui-client/components/lozenge';
import canAccess from 'district-ui-client/helpers/can-access';
import { FeatureAccess } from 'district-ui-client/services/feature-control';
import type { ReportRegistryService } from 'district-ui-client/services/report-registry';
import { Product } from 'district-ui-client/domain/product';
import { and, not } from 'ember-truth-helpers';
interface Signature {
    Element: HTMLElement;
}
export class SiteHeader extends Component<Signature> {
    @service
    logout: LogoutService;
    @service
    activeRoute: ActiveRouteService;
    @service
    session: SessionService;
    get subscriptionType() {
        return this.activeRoute.maybeSubscriptionType;
    }
    get showDistrictName() {
        return !this.subscriptionType || this.activeRoute.isReporting;
    }
    static{
        template_c20b0d0745454484a89896c5cb4769c8(`
    <header
      class="border-neutral-75 flex items-center justify-between border-b-2 border-solid bg-white px-4 lg:px-8 print:hidden"
      ...attributes
    >
      <Tabs @districtSubscriptions={{this.session.currentDisco.subscriptionTypes}} />
      <div class="hidden md:block">
        {{#if this.showDistrictName}}
          <div class="text-base font-medium text-neutral-400">{{this.session.currentDistrict.name}}</div>
        {{else}}
          <ProductLogos @subscriptionType={{this.subscriptionType}} />
        {{/if}}
      </div>
      <div class="flex">
        <Settings @isSettingsRoute={{this.activeRoute.isSettings}} />
        <VerticalDivide class="mx-4 self-center bg-neutral-50" />
        <LogoutButton {{on "click" this.logout.logoutAndRedirect}} />
      </div>
    </header>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
interface SettingsSignature {
    Element: HTMLElement;
    Args: {
        isSettingsRoute?: boolean;
    };
}
class Settings extends Component<SettingsSignature> {
    // We need the LinkTo to handle the event first, while the element is still rendered, then close the dropdown.
    // Otherwise, the page hard refreshes. see: https://github.com/cibernox/ember-basic-dropdown/issues/528
    delayClose = (closeAction: () => unknown)=>{
        runTask(this, ()=>{
            closeAction();
            // This is to prevent the button from staying focused after the dropdown closes
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
        });
    };
    static{
        template_c20b0d0745454484a89896c5cb4769c8(`
    <PopoverStandardDropdown as |dropdown status actions|>
      <SiteHeaderNavButton
        data-test-settings-trigger
        @currentWhen={{@isSettingsRoute}}
        @isOpen={{status.isOpen}}
        {{dropdown.makeTrigger}}
        ...attributes
      >
        <FaIcon @icon="gear" />
        <div class="relative inline-block">
          {{#if (FeatureNotification FeatureNotificationId.SetAcademicStandards)}}
            <span class="mr-1">{{t "settings.title"}}</span>
            <NotificationDot class="absolute -right-1.5 -top-0.5" />
          {{else}}
            <span>{{t "settings.title"}}</span>
          {{/if}}
        </div>
        <FaIcon @icon="angle-down" />
      </SiteHeaderNavButton>
      <dropdown.content>
        {{! The button trigger box is bigger than the button itself.
        The negative margin here counters the my-3 + h-1 below the button, to end up with the intended 1 unit gap }}
        <ThemedDropdownContent class="!-mt-3" @constrainWidth={{true}}>
          <ThemedMenu>
            <ThemedMenuGroup>
              <ThemedMenuItemLinkTo
                class="flex items-center gap-2"
                @route="settings.district-coordinator"
                {{on "click" (fn this.delayClose actions.close)}}
              >
                <FaIcon @icon="circle-user" />
                <span>
                  {{t "settings.myAccount"}}
                </span>
              </ThemedMenuItemLinkTo>
              {{#if (canAccess FeatureAccess.Standards)}}
                <ThemedMenuItemLinkTo
                  class="flex items-center gap-2"
                  @route="settings.district-settings"
                  {{on "click" (fn this.delayClose actions.close)}}
                >
                  <FaIcon @icon="sliders" />
                  <span class="flex-grow">
                    {{t "settings.district"}}
                  </span>
                  {{#if (FeatureNotification FeatureNotificationId.SetAcademicStandards)}}
                    <Lozenge class="shrink-0 grow-0">{{t "new"}}</Lozenge>
                  {{/if}}
                </ThemedMenuItemLinkTo>
              {{/if}}
            </ThemedMenuGroup>
          </ThemedMenu>
        </ThemedDropdownContent>
      </dropdown.content>
    </PopoverStandardDropdown>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
class ProductLogos extends Component<{
    Element: HTMLDivElement;
    Args: {
        subscriptionType?: SubscriptionType;
    };
}> {
    @service
    reportRegistry: ReportRegistryService;
    get showFPLogo(): boolean {
        return this.reportRegistry.reportPathsForProduct(Product.FP).length > 0;
    }
    static{
        template_c20b0d0745454484a89896c5cb4769c8(`
    <div data-test-product-logos class="flex h-12 max-h-12 items-center justify-center gap-3" ...attributes>
      {{#if (eq @subscriptionType SubscriptionType.Reading)}}
        <img src={{reLogo}} alt={{t "products.reading-eggs"}} />
        <img src={{rexLogo}} alt={{t "products.reading-eggspress"}} />
        {{#if this.showFPLogo}}
          <img src={{fpLogo}} alt={{t "products.fast-phonics"}} />
        {{/if}}
      {{/if}}
      {{#if (eq @subscriptionType SubscriptionType.Maths)}}
        <img class="h-10" src={{msLogo}} alt={{t "products.mathseeds"}} />
      {{/if}}
      {{#if (eq @subscriptionType SubscriptionType.Writing)}}
        <img src={{wlLogo}} alt={{t "products.writing-legends"}} />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const VerticalDivide: TOC<{
    Element: HTMLDivElement;
}> = template_c20b0d0745454484a89896c5cb4769c8(`
  <div class="inline-block h-6 w-0.5" ...attributes></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface SiteHeaderNavLinkSignature {
    Element: HTMLAnchorElement;
    Args: {
        route: string;
        subscriptionType: SubscriptionType;
    };
    Blocks: {
        default: [];
    };
}
const SiteHeaderNavLink: TOC<SiteHeaderNavLinkSignature> = template_c20b0d0745454484a89896c5cb4769c8(`
  <LinkTo
    @route={{@route}}
    @models={{array @subscriptionType}}
    class="group inline-block text-base font-medium transition-colors duration-75 ease-in-out focus:outline-none
      {{if (eq @subscriptionType SubscriptionType.Reading) 'selected:text-purply-pink-300'}}
      {{if (eq @subscriptionType SubscriptionType.Maths) 'selected:text-oceany-blue-350'}}
      {{if (eq @subscriptionType SubscriptionType.Writing) 'selected:text-forest-green-350'}}
      selected:[&_.button-underline]:bg-current"
    ...attributes
  >
    {{! separator to keep text vertically centered, balancing the line on the other side }}
    <div class="h-1 w-full" />
    <div
      class="group-hover:bg-neutral-75 group-focus-visible:outline-blue-325 my-3 rounded-lg bg-white px-4 py-2 group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-offset-2 group-active:bg-neutral-100"
    >{{yield}}</div>
    <div class="button-underline h-1 w-full rounded-t-sm transition-colors duration-200 ease-in-out"></div>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface SiteHeaderNavButtonSignature {
    Element: HTMLButtonElement;
    Args: {
        isOpen?: boolean;
        /**
     * True if currently on one of the pages from the dropdown
     */ currentWhen?: boolean;
    };
    Blocks: {
        default: [];
    };
}
const SiteHeaderNavButton: TOC<SiteHeaderNavButtonSignature> = template_c20b0d0745454484a89896c5cb4769c8(`
  <button
    type="button"
    class="text-neutral-250 group inline-block text-base font-medium focus:outline-none"
    ...attributes
  >
    {{! separator to keep text vertically centered, balancing the line on the other side }}
    <div class="h-1 w-full" />
    <div
      class="{{if (and @currentWhen (not @isOpen)) 'text-blue-325'}}
        {{if @isOpen 'text-blue-350 bg-blue-100' 'group-hover:bg-neutral-75 group-active:bg-neutral-100'}}
        group-focus:outline-blue-325 my-3 space-x-2 rounded-lg px-4 py-2 group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-offset-2"
    >
      {{yield}}
    </div>
    <div
      class="{{if @currentWhen 'bg-blue-325'}} h-1 w-full rounded-t-sm transition-colors duration-200 ease-in-out"
    ></div>
  </button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const LogoutButton: TOC<{
    Element: HTMLButtonElement;
}> = template_c20b0d0745454484a89896c5cb4769c8(`
  <button
    type="button"
    class="text-neutral-250 group inline-block text-base font-medium focus:outline-none"
    ...attributes
  >
    <div
      class="group-hover:bg-neutral-75 group-focus-visible:outline-blue-325 my-4 space-x-2 rounded-lg px-4 py-2 group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-offset-2 group-active:bg-neutral-100"
    >
      <FaIcon @icon="right-from-bracket" class="inline" /><span>{{t "components.topNav.logout"}}</span>
    </div>
  </button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Tabs: TOC<{
    Element: HTMLDivElement;
    Args: {
        districtSubscriptions: SubscriptionType[];
    };
}> = template_c20b0d0745454484a89896c5cb4769c8(`
  <div class="flex items-center justify-center gap-3" ...attributes>
    {{#each @districtSubscriptions as |subscriptionType|}}
      <SiteHeaderNavLink
        data-test-subscription-tab={{subscriptionType}}
        @route="subscription-type"
        @subscriptionType={{subscriptionType}}
      >
        {{t subscriptionType}}
      </SiteHeaderNavLink>
    {{/each}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SiteHeader;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        SiteHeader: typeof SiteHeader;
    }
}
