import { template as template_8a4b2b4f9c2d48a0a8064d8b2ae2ac47 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import type Owner from '@ember/owner';
import { service } from '@ember/service';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { AlertType, InlineAlert } from 'district-ui-client/components/inline-alert';
import { ThemedLinkTo } from 'district-ui-client/components/themed-button';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import { isNotFoundError } from 'district-ui-client/errors/http-error';
import type SessionService from 'district-ui-client/services/session';
import { t } from 'ember-intl';
import { trackedFunction } from 'reactiveweb/function';
interface Signature {
    Element: HTMLDivElement;
    Args: {
        subscriptionType: SubscriptionType;
    };
}
export class CompleteSetupInlineAlert extends Component<Signature> {
    @service
    session: SessionService;
    @service
    store: Store;
    cleverDistrictId?: string;
    // we need to set this once rather than track it, as due to its implementation it flips betweeen nullish and the id
    constructor(owner: Owner, args: Signature['Args']){
        super(owner, args);
        this.cleverDistrictId = this.session.currentDistrict.cleverDistrictMatch?.cleverDistrictRelationId;
    }
    fetchCleverSchools = trackedFunction(this, async ()=>{
        // if there's no clever-district id, then no point showing the alert
        if (!this.cleverDistrictId) return [];
        try {
            // even with the clever-district id, it may not be downloaded - so this call may 404
            const cleverSchools = await this.store.query('clever/clever-school', {
                scope: `clever-districts/${this.cleverDistrictId}`
            });
            return cleverSchools.slice();
        } catch (e: unknown) {
            if (isNotFoundError(e)) return [];
            return [];
        }
    });
    get cleverSchools() {
        return this.fetchCleverSchools.value;
    }
    get unsyncedSchoolsCount() {
        return this.cleverSchools?.filter((school)=>!school.hasCompletedSync)?.length ?? 0;
    }
    get cleverSchoolsCount() {
        return this.cleverSchools?.length ?? 0;
    }
    get isCleverInProgressForSubscriptionType() {
        const cleverDistrictMatch = this.session.currentDistrict.cleverDistrictMatch;
        if (!cleverDistrictMatch) return false;
        return (cleverDistrictMatch.getCleverSharedByType(this.args.subscriptionType) && !cleverDistrictMatch.getCleverManagedByType(this.args.subscriptionType));
    }
    get isVisible() {
        return this.isCleverInProgressForSubscriptionType && this.unsyncedSchoolsCount > 0;
    }
    static{
        template_8a4b2b4f9c2d48a0a8064d8b2ae2ac47(`
    {{#if this.isVisible}}
      <InlineAlert data-test-complete-setup-alert @type={{AlertType.Warning}} ...attributes>
        <div class="space-y-2">
          <h2 class="m-0 text-sm font-medium">{{t "clever.completeSetupInlineAlert.title"}}</h2>
          <p class="m-0 text-sm">
            <span data-test-sync-stats>{{t
                "clever.completeSetupInlineAlert.stats"
                unsyncedSchoolsCount=this.unsyncedSchoolsCount
                cleverSchoolsCount=this.cleverSchoolsCount
              }}</span>
            <span>{{t "clever.completeSetupInlineAlert.motivation"}}</span>
          </p>
          <ThemedLinkTo @style="theme" @route="clever.dashboard.unsynced">
            <span>{{t "clever.completeSetupInlineAlert.button"}}</span>
            <FaIcon @icon="arrow-right" />
          </ThemedLinkTo>
        </div>
      </InlineAlert>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
