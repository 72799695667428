import { template as template_ade77efcc0174f66aa5f0d0f03218e0d } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const ModalDefaultFooter: TOC<Signature> = template_ade77efcc0174f66aa5f0d0f03218e0d(`
  <div ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalDefaultFooter;
