import { template as template_40afc04a68eb42e8a280fa78e55989f7 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type District from 'district-ui-client/models/district';
import { SelectBox } from 'district-ui-client/components/select-box';
import type { IntlService } from 'ember-intl';
import { t } from 'ember-intl';
import { ThemedButton } from 'district-ui-client/components/themed-button';
import { on } from '@ember/modifier';
import type Owner from '@ember/owner';
import { trackedFunction } from 'reactiveweb/function';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { AlertType, InlineAlert } from 'district-ui-client/components/inline-alert';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import type FlashQueueService from 'district-ui-client/services/flash-queue';
import { isForbiddenError, shouldReportError, isUnauthorizedError } from 'district-ui-client/errors/http-error';
import type AuthToken from '@blakeelearning/auth/services/auth-token';
import type { Log } from '@blakeelearning/log';
import { ThemedModal, ModalBody, ModalFooter, ModalHeader, ModalHeading } from 'district-ui-client/components/themed-modal';
import { task } from 'ember-concurrency';
import type { TOC } from '@ember/component/template-only';
import type StandardsSet from 'district-ui-client/models/standards-set';
import type { SelectOption } from '@blakeelearning/ember-select';
import type FeaturesService from 'district-ui-client/services/features';
interface Signature {
    Element: HTMLDivElement;
    Args: {
        district: District;
        countryCode: string;
    };
}
const SpinLoader: TOC<{
    Element: SVGElement;
}> = template_40afc04a68eb42e8a280fa78e55989f7(`
  <FaIcon @icon="circle-notch" @spin={{true}} ...attributes />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export class StandardsPicker extends Component<Signature> {
    @service
    authToken: AuthToken;
    @service
    store: Store;
    @service
    flashQueue: FlashQueueService;
    @service
    intl: IntlService;
    @service
    log: Log;
    @service
    features: FeaturesService;
    @tracked
    selected?: string;
    @tracked
    showConfirmation = false;
    constructor(owner: Owner, args: Signature['Args']){
        super(owner, args);
        this.selected = this.args.district.standardsSet?.id;
    }
    get isDisabled(): boolean {
        return !this.selected || this.selected === this.args.district.standardsSet?.id || this.save.isRunning;
    }
    loadOptions = trackedFunction(this, async ()=>{
        const query = this.features.isEnabled('duiGroupedStandardsFF') ? {} : {
            filter: {
                'country-code': this.args.countryCode
            }
        };
        const standardsSets = (await this.store.query('standards-set', query)).slice();
        const regionNames = new Intl.DisplayNames([], {
            type: 'region'
        }) // uses locale set in the browser
        ;
        // FF on - sort by matching country code group first, then alphabetical groups + alphabetical options in each group (but CCSS first)
        // FF off - sort by CCSS first, then alphabetical
        if (this.features.isEnabled('duiGroupedStandardsFF')) {
            return standardsSets.sort((a, b)=>{
                // if same countrycode group
                if (a.countryCode === b.countryCode) {
                    if (a.code === 'CCSS') return -1 // CCSS option always first if present within group
                    ;
                    if (b.code === 'CCSS') return 1;
                    if (a.name === 'Australia V9') return -1 // Australia V9 option always first if present within group
                    ;
                    if (b.name === 'Australia V9') return 1;
                    return a.name.localeCompare(b.name) // otherwise sort by option label
                    ;
                }
                // else differing countrycode groups - if either group matches the current country code, it goes first
                if (a.countryCode === this.args.countryCode) return -1;
                if (b.countryCode === this.args.countryCode) return 1;
                return a.countryCode.localeCompare(b.countryCode) // otherwise alphabetical groups
                ;
            }).map((standard)=>({
                    value: standard.id,
                    label: `${standard.name} (${standard.code})`,
                    group: regionNames.of(standard.countryCode)
                }));
        } else {
            return standardsSets.sort((a, b)=>{
                if (a.code === 'CCSS') return -1 // CCSS always first
                ;
                if (b.code === 'CCSS') return 1;
                return a.name.localeCompare(b.name) // otherwise alphabetical
                ;
            }).map((standard)=>({
                    value: standard.id,
                    label: `${standard.name} (${standard.code})`
                }));
        }
    });
    get selectedStandardsSet() {
        if (!this.selected) return null;
        return this.store.peekRecord('standards-set', this.selected);
    }
    save = task({
        drop: true
    }, async ()=>{
        const standardsSet = this.selectedStandardsSet;
        if (!standardsSet) return;
        this.args.district.standardsSet = standardsSet;
        try {
            await this.args.district.save();
            this.showConfirmation = false;
            this.flashQueue.addSuccess({
                title: this.intl.t('stateAcademicStandards.success'),
                subtitle: this.intl.t('stateAcademicStandards.successDescription', {
                    name: standardsSet.name,
                    htmlSafe: true
                })
            });
        } catch (error: unknown) {
            if (isUnauthorizedError(error) || isForbiddenError(error)) {
                this.authToken.redirectToLogin();
                return;
            }
            this.flashQueue.addFail({
                title: this.intl.t('stateAcademicStandards.error'),
                subtitle: this.intl.t('pleaseCheckYourInternetTryAgain')
            });
            if (shouldReportError(error)) {
                this.log.error('Failed to set state standards', error || 'undefined error');
            }
        }
    });
    get options(): SelectOption[] {
        return this.loadOptions.value ?? [];
    }
    get districtHasStandardsSet() {
        return Boolean(this.args.district.standardsSet);
    }
    onReset = ()=>{
        this.selected = this.args.district.standardsSet?.id;
    };
    onCancel = ()=>{
        if (this.save.isRunning) return;
        this.showConfirmation = false;
    };
    onSelect = ({ value }: SelectOption)=>{
        this.selected = value;
    };
    onShowConfirmation = ()=>{
        this.showConfirmation = true;
    };
    static{
        template_40afc04a68eb42e8a280fa78e55989f7(`
    <div ...attributes>
      {{#if this.loadOptions.isPending}}
        <div class="w-full text-center">
          <SpinLoader class="text-neutral-250 text-3xl" />
        </div>
      {{else if this.loadOptions.isRejected}}
        <InlineAlert @type={{AlertType.Warning}}>
          <div class="space-y-2">
            <h2 class="m-0 text-sm font-medium">
              {{t "stateAcademicStandards.unableToLoadOptions"}}
            </h2>
            <p class="m-0 text-sm">{{t "checkInternetReloadPage"}}</p>
          </div>
        </InlineAlert>
      {{else if this.loadOptions.isResolved}}
        <div class="text-neutral-250 text-base font-medium">
          {{#if this.districtHasStandardsSet}}
            {{t "stateAcademicStandards.current"}}
          {{else}}
            {{t "stateAcademicStandards.unset"}}
          {{/if}}
        </div>
        <SelectBox
          class="shadow-tile mt-3"
          @style="neutral"
          @placeholder={{t "stateAcademicStandards.selectPrompt"}}
          @searchPlaceholder={{t "stateAcademicStandards.searchPrompt"}}
          @value={{this.selected}}
          @options={{this.options}}
          @onSelect={{this.onSelect}}
          @matchTriggerWidth={{true}}
        />
        <div class="mt-6 flex items-center justify-center space-x-3">
          <ThemedButton data-test-cancel @style="neutral" {{on "click" this.onReset}} @disabled={{this.isDisabled}}>
            {{t "cancel"}}
          </ThemedButton>
          <ThemedButton
            data-test-submit
            @style="theme"
            {{on "click" this.onShowConfirmation}}
            @disabled={{this.isDisabled}}
          >
            {{t "stateAcademicStandards.applyToAllSchools"}}
          </ThemedButton>
        </div>
      {{/if}}
    </div>
    <ConfirmModal
      @show={{this.showConfirmation}}
      @standardsSet={{this.selectedStandardsSet}}
      @onCancel={{this.onCancel}}
      @save={{this.save}}
      @showExistingDataMessage={{this.districtHasStandardsSet}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
interface ConfirmModalSignature {
    Element: HTMLDivElement;
    Args: {
        show?: boolean;
        standardsSet: Nullable<StandardsSet>;
        onCancel: () => void;
        save: StandardsPicker['save'];
        showExistingDataMessage?: boolean;
    };
}
export const ConfirmModal: TOC<ConfirmModalSignature> = template_40afc04a68eb42e8a280fa78e55989f7(`
  <ThemedModal @show={{@show}} @close={{@onCancel}}>
    <ModalHeader>
      <ModalHeading>{{t "confirm"}}</ModalHeading>
    </ModalHeader>
    <ModalBody class="w-[600px] space-y-4 text-sm">
      <div>{{t "stateAcademicStandards.confirm.effect" standard=@standardsSet.name htmlSafe=true}}</div>
      <div>
        {{t "stateAcademicStandards.confirm.leadTime" htmlSafe=true}}
        {{#if @showExistingDataMessage}}
          {{t "stateAcademicStandards.confirm.existingData"}}
        {{/if}}
      </div>
    </ModalBody>
    <ModalFooter>
      <ThemedButton
        data-test-confirm-cancel
        class="min-w-24"
        @disabled={{@save.isRunning}}
        @style="text"
        {{on "click" @onCancel}}
      >{{t "cancel"}}</ThemedButton>
      <ThemedButton
        data-test-confirm-submit
        class="min-w-24"
        @disabled={{@save.isRunning}}
        @style="theme"
        {{on "click" @save.perform}}
      >
        {{#if @save.isRunning}}
          <SpinLoader class="text-white" />
        {{else}}
          {{t "confirm"}}
        {{/if}}
      </ThemedButton>
    </ModalFooter>
  </ThemedModal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
