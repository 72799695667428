import { template as template_83f38c92862b4b5e9858d7ed8357e6df } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template_83f38c92862b4b5e9858d7ed8357e6df(`
    <div class="flex-shrink flex-grow overflow-y-auto px-8 py-10">
      {{outlet}}
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
