import { template as template_cc1125e7e5c04b91a1b3c2e7446f1a67 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export const DividerHorizontal: TOC<{
    Element: HTMLHRElement;
}> = template_cc1125e7e5c04b91a1b3c2e7446f1a67(`
  <hr class="h-[2px] bg-neutral-50" ...attributes />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
